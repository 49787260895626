.accordionSection {
  display: flex;
  flex-direction: column;
}

.accordionTitle {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}


.accordionContent {
  overflow: hidden;
  transition: max-height 0.6s ease;
  width: fit-content;
  block-size: fit-content;
  margin: 0 auto;
}

.accordionText {
  color: #000;
  text-align:center;
}

div.col > nav > a.nav-link {
  padding:0;
}

.col nav {
  position: fixed;
  height: 100%;
  left: 0px;
  top:62px;
  background: #f8f9fa;
}

.col nav .nav-link {
  display: block;
}

