body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > div > a {
    position: relative;
    left: -3px;
}

#app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > div > ul {
  padding: 0px 16px;
}

.chapterNavMobile {
  position: fixed;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  top: 72px;
  left: 0px;
  right: 0px;
  z-index: 2;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.3);
}

.chapterNavMobile h3 {
  position: relative;
  margin: 0 auto;
}

.vertical-nav {
  flex-direction: column;
}

.card-img-top {
  margin-top: 6px;
}

.audio-player-container {
  display: flex;
  flex-direction: column;
}

.fixed-audio-player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #282828;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.fixed-audio-player button {
  background-color: #1db954;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.fixed-audio-player button:hover {
  background-color: #1ed760;
}

.audio-text {
  margin-top: 10px;
  padding-bottom: 70px; /* Add some padding to avoid overlapping with the audio player */
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
}

audio {
  width: 100%; /* Make the audio player take the full width */
}

.jumbotron { 
  background:rgba(0,0,0,0.001);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#app > div.container {
  margin-top: 3.1rem;
}

.logo {
  margin-right: 30px;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

#qsLoginBtn {
  background-color:#fff;
  color: #000;
  border-color:#000;
}

@media (max-width: 991px) {
  div.col > nav {
    position:relative;
    top:-35px;
    left:0px;
  }
  .offset-sm-1 {
    margin-left: 0px;
  }

  .timeline-container { 
    margin-right:15%;
  }

}

@media (min-width: 576px) and (max-width: 768px) {

  .navbar-light .navbar-nav .nav-link {
    text-align: center;
  }

  .intro-container {
    position: relative;
    top: 30px;
  }

  #app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > div > a {
    padding: 0.5rem 1rem!important;
  }
  
  #app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > li{
    position: absolute;
    right: 70px;
    top: 10px;
  }

  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
  
  .mt-5, .my-5 {
    margin-top: 4.5rem !important;
  }

  div.col > nav {
    position:relative;
    top:-50px;
    left:0px;
  }

  .chapter-info {
    text-align: center;
  }

  .about-container video {
    width: 55%;
  }
  
  .timeline-marker {
    display:none;
  }

}

.navbar-light .navbar-nav .nav-link {
  color: #000;  
}


#app > div.nav-container > nav > div > li > a {
  padding:0;
}

.nav-item {
  list-style:none;
}
.nav-container {
  position: fixed;
  z-index: 3;
  width: 100%;
}
.coursenav {
  width: 200px;
  height: 100%;
}

#profileDropDown {
  padding: 0px;
}

div.nav-container > nav > div > ul > li > a {
   padding-right: 20px;
   padding:0;
}

.quiz h1 {
  text-align: center;
}

.score-board {
  width: 100%;
  height: 100%;
  padding: 25px;
}

.score-board p {
  font-size:28px;
  text-align: center;
}

.score-board button {
  color: #000;
  background-color: #fff;
  border-color: #6c757d;
  width: 100%;
  height: 65px;
  font-size: 25px;
  margin-top: 35px
}
.container-quiz {
  margin-top:40px; 
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.container-quiz h1 {
  text-align: center;
}

.question {
  font-size: 2.1em;
  font-weight: bold;
  margin-bottom: 25px;
  width: 100%;

}

.questionBox {
  background: #fff;
  opacity: 1;
  position: absolute;
  margin-top: 5px;
  padding: 25px;
  box-sizing: border-box;
  width: 100%;
}
.questionBox button {
    color: #000;
    background-color: #fff;
    border-color: #6c757d;
    width:100%;
    margin-bottom: 25px;
    font-size: 25px;
}
 
video, .portrait {
  width: 60%;
  border-radius: 50%;
  border: 1px solid;
  margin: 0 auto;
  display: block;
}

.course-paragraph {
  width: 70%;
  display:block;
  margin: 0 auto;
}

.capitalisation {
  font-size:60px;
  line-height: 50px;
  float: left;
  margin-right: 8px;
}

.btn {
  margin: 4px;
}

.profile {
  margin-top: 130px;
}

blockquote {
  font-size:0.8rem;
  padding:5px 0 0 0;
}

a:hover {
  color: #ff9a9a;
  text-decoration: none;
}

.example-header {
  background: #3d4351;
  color: #fff;
  font-weight: 300;
  padding: 3em 1em;
  text-align: center;
}
.example-header h1 {
  color: #fff;
  font-weight: 300;
  margin-bottom: 20px;
}
.example-header p {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}

.container-fluid .row {
  padding: 0 0 4em 0;
}
.container-fluid .row:nth-child(even) {
  background: #f1f4f5;
}

.example-title {
  text-align: center;
  margin-bottom: 60px;
  padding: 3em 0;
  border-bottom: 1px solid #e4eaec;
}
.example-title p {
  margin: 0 auto;
  font-size: 16px;
  max-width: 400px;
}
  
.jumbotron {
  padding: 26% 0rem;
}

.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.timeline-container {
  width:70%;
}

.timeline h3 {
  line-height: inherit;
}

.timeline-item {
  padding-left: 40px;
  position: relative;
}

.timeline-item div.row > div > h3 {
  margin-top: 10px;
  line-height: 1.2;
}

.timeline-item:last-child {
  padding-bottom: 0;
}

.chapter-info {
  font-size: 12px;
  font-weight:700;
  letter-spacing: 3px;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}

/*----- TIMELINE MARKER -----*/
.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}
.timeline-marker:before {
  background: #ff6b6b;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.timeline-marker:after {
  content: "";
  width: 3px;
  background: #ccd5db;
  display: block;
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 6px;
}

.timeline-item:last-child .timeline-marker:after {
  content: none;
}

.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid #ff6b6b;
}

/*----- TIMELINE CONTENT -----*/
.timeline-content {
  padding-bottom: 40px;
}
.timeline-content p:last-child {
  margin-bottom: 0;
}

.period .chapter-info {
  display: none;
}

.period .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 3px solid #ccd5db;
  border-bottom: 3px solid #ccd5db;
}
.period .timeline-marker:after {
  content: "";
  height: 32px;
  top: auto;
}
.period .timeline-content {
  padding: 40px 0 70px;
}
.period .timeline-title {
  margin: 0;
}

@media (min-width: 0px) and (max-width: 576px) {

  .navbar-light .navbar-nav .nav-link {
    text-align: center;
  }

  .timeline-marker {
    display:none;
  }

  .intro-container {
    position: relative;
    top: 40px;
  }

  .quiz, h1 {
    font-size: 2rem;
    position: relative;
    top:15px;
  }

  .questionBox button {
    margin-bottom: 5px!important;
  }

  .questionBox {
    left: 0px;
  }


  .container-quiz h1 {
    font-size: 1.5rem!important;
  }

  #app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > div > a {
    padding: 0 !important;
  }
  
  #app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > li {
    position: absolute;
    right: 70px;
    top: 3px;
  }
  .timeline-split .timeline-marker, .timeline-centered .timeline-marker {
    display:none;
  }

}


@media (max-width: 992px)  {
  .course-paragraph {
    width: 100%;
    display:block;
    margin: 0 auto;
  }
  .timeline-container { 
    width: 100%;
  }

  .timeline-item {
    padding: 0;
  }

  .container-quiz {
    margin-top:40px; 
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  .quiz, h1 {
    position: relative;
    top:14px;
  }

  .container-quiz h1 {
    font-size: 2rem;
    position: relative;
    top: 0px;
  }
  
  .question {
    font-size: 2.1em;
    font-weight: bold;
    margin-top: 10px;
    width: 100%;
  
  }
  
  .questionBox {
    background: #fff;
    opacity: 1;
    position: absolute;
    top: 50px;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
  }
  .questionBox button {
      color: #000;
      background-color: #fff;
      border-color: #6c757d;
      width:100%;
      margin-bottom: 25px;
      font-size: 15px;
  }

}


@media (min-width: 768px) {

  #app > div.nav-container > nav > div > div.d-flex.justify-content-between.w-100 > div > a {
    padding: 0 !important;
  }
  .timeline-split .timeline, .timeline-centered .timeline {
    display: table;
  }
  .timeline-split .timeline-item, .timeline-centered .timeline-item {
    padding: 0;
  }
  .timeline-split .chapter-info, .timeline-centered .chapter-info,
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker,
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content,
  .timeline-split .period .chapter-info,
  .timeline-centered .period .chapter-info {
    display: block;
    text-align: center;
  }
  .timeline-split .timeline-marker, .timeline-centered .timeline-marker {
    display:none;
  }

  .progressMainWrapper {
    height: 2px;
    position: fixed;
    top: 129px;
    left: 0;
    z-index: 2;
    width: 100%;
  }

  .portrait, video {
    width: 30%;
  }
}

/*----------------------------------------------
        MOD: TIMELINE CENTERED
    ----------------------------------------------*/
@media (min-width: 992px) {
  .timeline-centered,
  .timeline-centered .timeline-item,
  .timeline-centered .chapter-info,
  .timeline-centered .timeline-marker,
  .timeline-centered .timeline-content {
    display: block;
    margin: 0;
    padding: 0;
  }
  .timeline-centered .timeline-item {
    padding-bottom: 40px;
    overflow: hidden;
  }
  .timeline-centered .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px;
  }
  .timeline-centered .chapter-info,
  .timeline-centered .timeline-content {
    width: 50%;
  }
  .timeline-centered > .timeline-item:nth-child(odd) .chapter-info {
    float: left;
    text-align: right;
    padding-right: 30px;
  }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 30px;
  }
  .timeline-centered > .timeline-item:nth-child(even) .chapter-info {
    float: right;
    text-align: left;
    padding-left: 30px;
  }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-content {
    float: left;
    text-align: right;
    padding-right: 30px;
  }
  .timeline-centered > .timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .timeline-centered .timeline-item.period {
    padding: 50px 0 90px;
  }
  .timeline-centered .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto;
  }
  .timeline-centered .period .timeline-title {
    left: auto;
  }
  div.col > nav > a > div > div {
    width: 190px;
  }

  .progressMainWrapper {
    height: 2px;
    position: fixed;
    top: 129px;
    left: 0;
    z-index: 2;
    width: 100%;
  }  

} 

#app > div.container > div.next-steps.my-5 > div:nth-child(2) > div:nth-child(3) > a {
  pointer-events: auto;
}

.progress-bar {
  background-color: #ffa500;
}

#app > div.profile.container {
  margin-top: 130px;
}

.progressMainWrapper {
  height: 2px;
  position: fixed;
  top: 78px;
  left: 0;
  z-index: 2;
  width: 100%;
}

.col nav {
  position: fixed;
  height: 100%;
  left: 0px;
  top: 70px;
  background: #f8f9fa;
}

.progressMainStyle {
  height: 2px;
  background-color: #00cc83;
}


.about-container {
  padding: 2rem;
}

.certificate img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.about-container p {
  text-align: center;
}

.about-container h2 {
  text-align: center;
}

.about-container button {
  display: flex;
  justify-content: center;
  align-items: center;
}


